import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const TopImage = () => {
    return (
        <StaticImage

            className="top-image"
            formats={["auto", "webp", "avif"]}
            src="../images/elx-1.jpg"
            quality={20}
            alt="Top picture"
        />
    )
}

export default TopImage